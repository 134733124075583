import { getAllowedRoles } from '@/../config/userRoles';

export default [
  {
    path: '',
    name: 'events',
    redirect: { name: 'events_book_events' },
  },
  {
    path: '',
    name: 'operations',
    redirect: { name: 'events_book_events' },
  },
  {
    path: 'events/book-events',
    name: 'events_book_events',
    component: () => import('@/components/events/Events'),
    meta: {
      title: 'Book Events - Events',
      allowedUserRoles: getAllowedRoles('events'),
    },
  },
  {
    path: 'events/full-offer',
    name: 'events_full_offer',
    component: () => import('@/components/events/Events'),
    meta: {
      title: 'Full Offer - Events',
      allowedUserRoles: getAllowedRoles('events'),
    },
  },
  {
    path: 'trading-ui/multiview',
    name: 'trading-ui-multiview',
    component: () => import('@/components/trading-ui/multiview/TradingUIMultiview'),
    meta: {
      title: 'Multiview - Trading UI',
      allowedUserRoles: getAllowedRoles('tradingUI'),
    },
  },
  {
    path: 'trading-ui/:eventId',
    name: 'trading-ui',
    component: () => import('@/components/trading-ui/singleview/TradingUISingleview'),
    meta: {
      title: 'Singleview - Trading UI',
      allowedUserRoles: getAllowedRoles('tradingUI'),
    },
  },
  {
    path: 'params-setup/:eventId',
    name: 'params-setup',
    component: () => import('@/components/player-setup/Index'),
    meta: {
      title: 'Params setup',
      allowedUserRoles: getAllowedRoles('playerSetup'),
    },
  },
  {
    path: 'bet-ticker',
    name: 'bet-ticker',
    component: () => import('@/components/bet-ticker/BetTickerPage'),
    meta: {
      title: 'Bet ticker',
      allowedUserRoles: getAllowedRoles('betTicker'),
    },
  },
  {
    path: 'liability',
    name: 'liability',
    component: () => import('@/components/liability/Liability'),
    meta: {
      title: 'Liability',
      allowedUserRoles: getAllowedRoles('liability'),
    },
  },
  {
    path: 'customer-profiling',
    name: 'customer-profiling',
    component: () => import('@/components/customer-profiling/CustomerProfiling'),
    children: [
      {
        path: '',
        name: 'customer-profiling-list',
        component: () => import('@/components/customer-profiling/list/CustomerProfilingList'),
        meta: {
          title: 'Customer Profiling List',
          allowedUserRoles: getAllowedRoles('customerProfiling'),
        },
      },
      {
        path: ':customerId',
        name: 'customer-profiling-details',
        component: () => import('@/components/customer-profiling/details/CustomerProfilingDetails'),
        meta: {
          title: 'Customer Profiling Details',
          allowedUserRoles: getAllowedRoles('customerProfiling'),
        },
      },
    ],
  },
  {
    path: 'odds-checker',
    name: 'odds-checker',
    redirect: { name: 'core-markets' },
    children: [
      {
        path: 'core-markets',
        name: 'core-markets',
        component: () => import('@/components/odds-checker/CoreMarkets'),
        meta: {
          title: 'Core markets',
          allowedUserRoles: getAllowedRoles('oddsChecker'),
        },
      },
      {
        path: 'player-props',
        name: 'player-props',
        component: () => import('@/components/odds-checker/PlayerProps'),
        meta: {
          title: 'Player props',
          allowedUserRoles: getAllowedRoles('oddsChecker'),
        },
      },
    ],
  },
  {
    path: 'manual-resulting',
    name: 'manual-resulting',
    component: () => import('@/components/manual-resulting/ManualResulting'),
    meta: {
      title: 'Manual resulting',
      allowedUserRoles: getAllowedRoles('manualResulting'),
    },
  },
  {
    path: 'audit-log/:eventId',
    name: 'audit-log',
    component: () => import('@/components/audit-log/AuditLog'),
    meta: {
      title: 'Audit log',
      allowedUserRoles: getAllowedRoles('auditLog'),
    },
  },
];
