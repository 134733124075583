import { pickBy, identity } from 'lodash';
import http from '@/services/http-client';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import routes from '@/../config/env';

const { accountInfo } = routes;

const formatUrl = (url) => {
  const { isSuperAdmin } = getSuperAdminData();
  if (isSuperAdmin) return `${accountInfo}/admin/${url}`;
  return `${accountInfo}/${url}`;
};

const formatData = (data) => {
  const { isSuperAdmin } = getSuperAdminData();
  if (isSuperAdmin) return pickBy(data, identity);
  const { operatorId, ...newData } = data;
  return pickBy(newData, identity);
};

export const flagCustomerProfile = ({ accountId, operatorId }) => http({
  method: 'post',
  url: formatUrl('account/flag'),
  data: formatData({
    accountId,
    operatorId,
  }),
});

export const unflagCustomerProfile = ({ accountId, operatorId }) => http({
  method: 'post',
  url: formatUrl('account/unflag'),
  data: formatData({
    accountId,
    operatorId,
  }),
});

export const setCustomerProfileFlag = ({ accountId, operatorId, isFlagged }) => {
  if (isFlagged) return flagCustomerProfile({ accountId, operatorId });
  return unflagCustomerProfile({ accountId, operatorId });
};

export const setCustomerProfileStrength = ({ accountId, operatorId, strength }) => http({
  method: 'post',
  url: formatUrl('account/set-strength'),
  data: formatData({
    accountId,
    operatorId,
    strength,
  }),
});

export const setCustomerProfileNickname = ({ accountId, operatorId, nickname }) => http({
  method: 'post',
  url: formatUrl('account/set-nickname'),
  data: formatData({
    accountId,
    operatorId,
    nickname,
  }),
});
